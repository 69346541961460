export const PERMISSIONS = {
    agency: {
        createPermission: 'agency_create',
        deletePermission: 'agency_delete',
        editPermission: 'agency_edit',
        viewPermission: 'agency_view',
        listPermission: 'agency_list',
    },
    branch: {
        createPermission: 'branch_create',
        deletePermission: 'branch_delete',
        editPermission: 'branch_edit',
        viewPermission: 'branch_view',
        listPermission: 'branch_list',
    },
    customer: {
        createPermission: 'customer_create',
        deletePermission: 'customer_delete',
        editPermission: 'customer_edit',
        viewPermission: 'customer_view',
        listPermission: 'customer_list',
    },
    location: {
        createPermission: 'location_create',
        deletePermission: 'location_delete',
        editPermission: 'location_edit',
        viewPermission: 'location_view',
        listPermission: 'location_list',
    },
    model: {
        createPermission: 'model_create',
        deletePermission: 'model_delete',
        editPermission: 'model_edit',
        viewPermission: 'model_view',
        listPermission: 'model_list',
    },
    pricingGroupSeason: {
        createPermission: 'pricingGroupSeason_create',
        deletePermission: 'pricingGroupSeason_delete',
        editPermission: 'pricingGroupSeason_edit',
        viewPermission: 'pricingGroupSeason_view',
        listPermission: 'pricingGroupSeason_list',
    },
    pricingGroup: {
        createPermission: 'pricingGroup_create',
        deletePermission: 'pricingGroup_delete',
        editPermission: 'pricingGroup_edit',
        viewPermission: 'pricingGroup_view',
        listPermission: 'pricingGroup_list',
    },
    rentable: {
        createPermission: 'rentable_create',
        deletePermission: 'rentable_delete',
        editPermission: 'rentable_edit',
        viewPermission: 'rentable_view',
        listPermission: 'rentable_list',
    },
    reservationPayment: {
        createPermission: 'reservationPayment_create',
        deletePermission: 'reservationPayment_delete',
        editPermission: 'reservationPayment_edit',
        viewPermission: 'reservationPayment_view',
        listPermission: 'reservationPayment_list',
    },
    reservation: {
        createPermission: 'reservation_create',
        deletePermission: 'reservation_delete',
        editPermission: 'reservation_edit',
        viewPermission: 'reservation_view',
        listPermission: 'reservation_list',
    },
    season: {
        createPermission: 'season_create',
        deletePermission: 'season_delete',
        editPermission: 'season_edit',
        viewPermission: 'season_view',
        listPermission: 'season_list',
    },
    user: {
        createPermission: 'user_create',
        deletePermission: 'user_delete',
        editPermission: 'user_edit',
        viewPermission: 'user_view',
        listPermission: 'user_list',
    },
    class: {
        createPermission: 'class_create',
        deletePermission: 'class_delete',
        editPermission: 'class_edit',
        viewPermission: 'class_view',
        listPermission: 'class_list',
    },
    rentableExtra: {
        createPermission: 'rentableExtra_create',
        deletePermission: 'rentableExtra_delete',
        editPermission: 'rentableExtra_edit',
        viewPermission: 'rentableExtra_view',
        listPermission: 'rentableExtra_list',
    },
    payments: {
        createPermission: 'payments_create',
        deletePermission: 'payments_delete',
        editPermission: 'payments_edit',
        viewPermission: 'payments_view',
        listPermission: 'payments_list',
    },
    paymentMethods: {
        createPermission: 'paymentMethods_create',
        deletePermission: 'paymentMethods_delete',
        editPermission: 'paymentMethods_edit',
        viewPermission: 'paymentMethods_view',
        listPermission: 'paymentMethods_list',
    },
    expenses: {
        createPermission: 'expenses_create',
        deletePermission: 'expenses_delete',
        editPermission: 'expenses_edit',
        viewPermission: 'expenses_view',
        listPermission: 'expenses_list',
        exportPermission: 'expenses_export',
    },
    expenseConfig: {
        createPermission: 'expenseConfig_create',
        deletePermission: 'expenseConfig_delete',
        editPermission: 'expenseConfig_edit',
        viewPermission: 'expenseConfig_view',
        listPermission: 'expenseConfig_list',
    },
    dashboard: {
        createPermission: 'dashboard_create',
        deletePermission: 'dashboard_delete',
        editPermission: 'dashboard_edit',
        viewPermission: 'dashboard_view',
        listPermission: 'dashboard_list',
    },
    configValue: {
        createPermission: 'configValue_create',
        deletePermission: 'configValue_delete',
        editPermission: 'configValue_edit',
        viewPermission: 'configValue_view',
        listPermission: 'configValue_list',
    },
    group: {
        createPermission: 'group_create',
        deletePermission: 'group_delete',
        editPermission: 'group_edit',
        viewPermission: 'group_view',
        listPermission: 'group_list',
    },
    permission: {
        createPermission: 'permission_create',
        deletePermission: 'permission_delete',
        editPermission: 'permission_edit',
        viewPermission: 'permission_view',
        listPermission: 'permission_list',
    },
    invoices: {
        createPermission: 'invoices_create',
        deletePermission: 'invoices_delete',
        editPermission: 'invoices_edit',
        viewPermission: 'invoices_view',
        listPermission: 'invoices_list',
    },
    bankAccount: {
        createPermission: 'bankAccount_create',
        deletePermission: 'bankAccount_delete',
        editPermission: 'bankAccount_edit',
        viewPermission: 'bankAccount_view',
        listPermission: 'bankAccount_list',
    },
    bankEntry: {
        createPermission: 'bankEntry_create',
        deletePermission: 'bankEntry_delete',
        editPermission: 'bankEntry_edit',
        viewPermission: 'bankEntry_view',
        listPermission: 'bankEntry_list',
    },
    branchCashbox: {
        createPermission: 'branchCashbox_create',
        deletePermission: 'branchCashbox_delete',
        editPermission: 'branchCashbox_edit',
        viewPermission: 'branchCashbox_view',
        listPermission: 'branchCashbox_list',
    },
    branchCashboxMovement: {
        createPermission: 'branchCashboxMovement_create',
        deletePermission: 'branchCashboxMovement_delete',
        editPermission: 'branchCashboxMovement_edit',
        viewPermission: 'branchCashboxMovement_view',
        listPermission: 'branchCashboxMovement_list',
    },
};
